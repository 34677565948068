import React, { useEffect, useState } from 'react'
import Drawer from '../Drawer/Drawer'
import MenuButton from '../MenuButton/MenuButton'
import { ArrowUpOutlined } from '@ant-design/icons';

function WithdrawPanel({ active, setActive }) {
  return (
    <Drawer active={active} setActive={setActive}>
      <div>Withdraw</div>
    </Drawer>
  )
}

export default function Withdraw(props) {
  const [active, setActive] = useState(false)
  useEffect(() => {
    if (active) {
      document.getElementById('root').classList.add('small')
    } else {
      document.getElementById('root').classList.remove('small')
    }
  }, [active])
  return (
    <div>
      <WithdrawPanel active={active} setActive={setActive} />
      <MenuButton text='Отправить' icon={<ArrowUpOutlined/>} onClick={() => setActive(true)} />
    </div>

  )
}

