import React, { useEffect } from 'react'
import { WalletOutlined, SettingOutlined, HistoryOutlined, FireOutlined } from '@ant-design/icons'
import './MainMenu.scss'
import { Link, set, useLocation } from 'react-router-dom'


const MenuButton = ({ to, icon = <WalletOutlined />, text }, props) => {
    const location = useLocation();

    const [active, setActive] = React.useState(false)
    
    // отслежитвать обновление URL
    useEffect(() => {
        if (location.pathname === to) {
            setActive(true)
        } else {
            setActive(false)
        }
     }, [location])


    return (
        <Link to={to} className={`MainMenu__button ${active ? 'active' : ''}`}>
            <div className='icon'>{icon}</div>
            <span className='desc'>{text}</span>
        </Link>
    )
}

export default function MainMenu(props) {
    return (
        <section className='MainMenu'>
            <MenuButton active to={'/wallet'} icon={<WalletOutlined />} text={'Кошелек'} />
            <MenuButton to={'/activity'} icon={<HistoryOutlined />} text={'Активности'} />
            <MenuButton to={'/payments'} icon={<FireOutlined />} text={'Платежи'} />
            <MenuButton to={'/settings'} icon={<SettingOutlined />} text={'Настройки'} />
        </section>
    )
}
