import React, { useEffect, useState } from 'react'
import Drawer from '../Drawer/Drawer'
import MenuButton from '../MenuButton/MenuButton'
import { GitlabOutlined, DoubleRightOutlined, RiseOutlined } from '@ant-design/icons';
import './InfoBlock.scss'

// function CreateBillPanel({ active, setActive }) {
//   return (
//     <Drawer active={active} setActive={setActive}>
//       <div>CreateBill</div>
//     </Drawer>
//   )
// }
const CreateIcon = ({ icon = <GitlabOutlined />, bg = 'green' }) => {
  return (
    <div className='ICON' style={{ backgroundColor: bg }}>
      {icon}
    </div>
  )
}
export function InfoBlockPart({ data }) {
  data = {
    icon: {
      icon: <GitlabOutlined />,
      bg: 'blue'
    },
    title: 'TonCoin',
    desc: 'Зарабатывай на TON до 4.41%',
    ...data
  }
  const [active, setActive] = useState(false)
  useEffect(() => {
    if (active) {
      document.getElementById('root').classList.add('small')
    } else {
      document.getElementById('root').classList.remove('small')
    }
  }, [active])
  return (
    <div className='InfoBlock'>
      <div className='icon'><CreateIcon {...data.icon} /></div>
      <div className='desc'>
        <div className='title'>{data.title}</div>
        <div className='desc'>{data.desc}</div>
      </div>
      <div className='data'><DoubleRightOutlined /></div>
    </div>

  )
}

export default function InfoBlock(props) {
  return (
    <div className='InfoBlockP'>
      <InfoBlockPart data={
        {
          icon: {
            icon: <GitlabOutlined />,
            bg: 'blue'
          },
          title: 'TonCoin',
          desc: '124.59₽ +2.37%',
        }
      } />
      <InfoBlockPart data={
        {
          icon: {
            icon: <RiseOutlined />,
            bg: 'green'
          },
          title: 'Стейкинг',
          desc: 'Зарабатывай на TON до 4.41%',
        }
      } />
    </div>
  )
}
