import React from 'react'
import { BiLoaderAlt } from 'react-icons/bi'
import s from './Loader.module.scss'

export default function Loader({ icon = <BiLoaderAlt /> }) {
    return (
        <div className={s['loader']}>
            <div className={s['loader--icon']}>{icon}</div>
        </div>
    )
}
