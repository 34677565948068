import React from 'react'
import s from './StatusConfirm.module.scss'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { HiBell } from 'react-icons/hi'

export default function StatusConfirm({}) {
    return (
        <div className={s['status__confirms']}>
            <div className={s['confirms__notification']}><HiBell /></div>
            <div className={s['confirm']}>
                <div className={s['confirm__icon']}><AiOutlineLoading3Quarters /></div>
                <div className={s['confirm__text']}>
                    <span className={s['text__title']}>Проверка транзакции в блокчейне</span>
                </div>
                

            </div>
        </div>
    )
}
