/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.5 src/components/TonC/TonCoins.glb --transform
*/

import React, { useEffect, useRef, useState } from 'react'
import { CameraControls, useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'
import { motion } from "framer-motion-3d"
import { vis } from './Animatins'
import { motion as motionFramer } from "framer-motion"
import { useControls } from 'leva'
import { CameraHelper } from 'three'
import { transform } from "framer-motion"
const deb = transform([0, 100], [0, 360])
export function Model(props) {
  const [scale, setScale] = useState(0.03)
  const [rotation, setRotation] = useState([0, 0, 0])
  const [position, setPosition] = useState([0, 0, 0])
  //
  const ref = useRef()

  const {
    active,
    rotateX,
    positionX,
    positionY,
    positionZ,
  } = useControls({
    active: true,
    rotateX: 2.34,
    positionX: 30,
    positionY: 20,
    positionZ: -40.00
  })

  const variants = {
    open: {},
    closed: {}
  }

  const [isOpen, setIsOpen] = useState(false)
  useEffect(() => {
    setIsOpen(active)
  }, [active])

  const { nodes, materials } = useGLTF('/TonCoins.glb')
  return (
    <>
      <motion.group
        {...props}
        dispose={null}>
        {/* <mesh geometry={nodes.Mesh_2.geometry} material={materials.PaletteMaterial001} /> */}
        {/* <CameraControls/> */}
        <motion.mesh
          transition={{
            duration: 2,
            ease: "easeInOut",
            times: 1,
            // repeat: Infinity,
            repeatDelay: 0.3
          }}
          animate={{
            rotateX: active ? deb(rotateX) : 1,
            x: active ? positionX : 0,
            y: active ? positionY : 0,
            z: active ? positionZ : 0,
            // positionZ: positionZ,
          }}
          // whileHover={{ scale: 0.05 }}
          // whileTap={{ scale: 0.07 }}
          //------------------------------------------------
          ref={ref}
          geometry={nodes.Base.geometry}
          material={materials.PaletteMaterial001}
          // position={position}
          // rotation={rotation}
          scale={scale}
        />
      </motion.group>
    </>
  )
}

useGLTF.preload('/TonCoins.glb')
