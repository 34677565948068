import { degreesToRadians } from "popmotion";
import { CameraControls, useGLTF } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";

import { motion } from "framer-motion-3d"
import { useControls } from "leva";
// import { motion  } from "framer-motion"
import { transform } from "framer-motion"
const deb = transform([0, 100], [0, 360])
// 
export function StarIcon({ isLiked, isHover }) {
    let size = 0.01
    // const {
    //     size,
    // } = useControls({
    //     size: {
    //         value: 0.01,
    //         min: 0.0001,
    //         max: 1,
    //         step: 0.01,
    //         label: "Size"
    //     }
    // })
    // const { nodes } = useGLTF("/star-icon.glb");
    const { nodes } = useGLTF("/ton.glb");
    console.log(nodes);


    return (
        <Canvas
            style={{
                pointerEvents: "none"
            }}
            resize={{ offsetSize: true }}
            dpr={[1, 2]}
            camera={{ position: [0, 0, 5.5], fov: 45 }}
        >
            {lights.map(([x, y, z, intensity], i) => (
                <pointLight
                    key={i}
                    intensity={intensity}
                    position={[x / 8, y / 8, z / 8]}
                    color="#fff"
                />
            ))}
            <group dispose={null}>
                {/* <CameraControls/> */}
                <motion.mesh
                    geometry={nodes.Logo.geometry}
                    rotation={[Math.PI / 2, 0, degreesToRadians(360)]}
                    scale={size}
                    animate={[isLiked ? "liked" : "unliked", isHover ? !isLiked ? "hover" : '' : '']}
                    variants={{
                        unliked: {
                            x: [0, 0],
                            y: [0, 0],
                            rotateX: [0, 0],
                            rotateY: [0, 0],
                            scale: size
                        },
                        liked: {
                            x: 4,
                            y: [0, -1.5, 1.4],
                            rotateY: [0, 0, 1],
                            rotateZ: [0, 2.7, 2.6],
                            transition: { duration: 0.5 }
                        },
                        hover: {
                            rotateY: [0, 3],
                            scale: size * 1.2,
                            transition: {
                                rotateY: { duration: 1.5, ease: "linear", repeat: Infinity }
                            }
                        }
                    }}
                >
                    <meshPhongMaterial
                        color="#ffdd00"
                        emissive="#ff9500"
                        specular="#fff"
                        shininess="100"
                    />
                </motion.mesh>
            </group>
        </Canvas>
    );
}

const lights = [
    [2, 1, 4, 1],
    [8, 0, 4, 1]
];
