import React from 'react'
import './Err404.scss'

export default function Err404() {
    return (
        <section className='Err404'>
            <div className='Data'>
                <span className='title'>404</span>
                <span className='desc'>Данный экран недоступен</span>
            </div>
        </section>
    )
}
