import { message } from 'antd';
import React from 'react'
import { useClipboard } from 'use-clipboard-copy';

export default function CopyPanel({ toCopy, alert = 'Скопировано', children }) {
    const [messageApi, contextHolder] = message.useMessage();
    const clipboard = useClipboard();

    const success = () => {
        messageApi.open({
            type: 'success',
            key: 'copped',
            content: alert,
        });
    };

    const copy = () => {
        try {
            console.log('toCopy', toCopy);
            clipboard.copy(toCopy);
        } catch {
            console.log('Не удалось скопировать');
        }
        success()
    }
    return (
        <>
            {contextHolder}
            <div onClick={() => copy()}>{children}</div>
        </>
    )
}
