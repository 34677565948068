import React, { useState } from 'react'

export default function HeaderPanel({ text = 'Кошелек', children }, props) {
    const [classList, setClassList] = useState('')
    // если мы прокрутили больше чем 100px то добавляем класс
    window.addEventListener('scroll', () => {
        if (window.scrollY > 30) {
            setClassList(' active')
        } else {
            setClassList('')
        }
    })
    return (
        <section className={`MainPage-header ${classList}`}>
            <span />
            <h1>{text}</h1>
            <h1>{children}</h1>
        </section>
    )
}
