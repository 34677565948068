import React from 'react';
import Footer from '../components/Footer/Footer';
import Header from '../components/Header/Header';
import '../assets/scss/global.scss';
import MainPage from '../components/MainPage/MainPage';
import { Helmet } from 'react-helmet';
import PaymentsPanel from '../components/PaymentsPanel/PaymentsPanel';


function Payments({setModalActive}) {
    return (
        <>
            <Helmet
                htmlAttributes={{ "lang": "en", "amp": undefined }} // amp takes no value
                title="FIRST HARVEST GUARANTEED! "
                titleTemplate="Progress Farm: %s"
                defaultTitle="Progress Farm"
                description="FIRST HARVEST GUARANTEED Progress Farm."
                meta={[
                    { "name": "description", "content": "Progress Farm." },
                    { "property": "og:type", "content": "website" },
                    { property: "og:image", content: "../assets/posters/poster_en.png" },
                    { property: "og:image:secure_url", content: "../assets/posters/poster_en.png" },
                    { property: "og:image:type", content: "image/png" }
                ]}
                // link={[
                //     { "rel": "canonical", "href": "http://mysite.com/example" },
                //     { "rel": "apple-touch-icon", "href": "http://mysite.com/img/apple-touch-icon-57x57.png" },
                //     { "rel": "apple-touch-icon", "sizes": "72x72", "href": "http://mysite.com/img/apple-touch-icon-72x72.png" }
                // ]}
                script={[
                    { "src": "http://include.com/pathtojs.js", "type": "text/javascript" },
                    { "type": "application/ld+json", innerHTML: `{ "@context": "http://schema.org" }` }
                ]}
            />
            <PaymentsPanel setModalActive={setModalActive} />
        </>
    );
}

export default Payments;