import React, { useEffect } from 'react'
import s from './Expire.module.scss'
import { Progress } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { setBillActive } from '../Header/store/billSlice'

export default function Expire() {
    const active = useSelector((state) => state.bill.active)
    const data = useSelector((state) => state.bill)
    const dispatch = useDispatch()
    const [timerActive, setTimerActive] = React.useState(false)
    const [timerId, setTimerId] = React.useState(null)
    const [percent, setPercent] = React.useState(0)
    const [timeout, setTime] = React.useState('00:00:00')
    const [color, setColor] = React.useState('#3ac270')
    const timer = React.useRef(null)

    const colorList = {
        green: '#3ac270',
        yellow: '#ffa64d',
        red: '#f70000'
    }
    // при удалении компонента остановить таймер


    const toTimer = () => {
        // каждую секунду
        let diff = new Date(data.expiresAt) - Date.now()
        console.log('timer', diff);
        let diffInMinutes = diff / 1000 / 60
        let totalInMinutes = data.lifeTime
        let percent = ((100 - (diffInMinutes / totalInMinutes) * 100).toFixed(1))
        if (percent > 80) setColor(colorList.yellow)
        if (percent >= 100) setColor(colorList.red)
        setPercent(percent)
        // в формате 00:52:23
        let hours = Math.floor(diff / 1000 / 60 / 60)
        let minutes = Math.floor((diff / 1000 / 60) % 60)
        let seconds = Math.floor((diff / 1000) % 60)
        let _tt = (`${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`)
        if (diff < 0) {
            _tt = '00:00:00'
            dispatch(setBillActive(false))
            setTime(_tt)
        } else {
            setTime(_tt)
            // toTimer()
        }
    }
    // при монтировании компонента запустить таймер
    React.useEffect(() => {
        toTimer()
        const timerId = setInterval(() => toTimer(), 1000)
        setTimerId(timerId)

        return () => { 
            console.log('unmount');
            clearInterval(timerId)
        }
    }, [])


    return (
        <div className={s['expire_container']}>
            <div className={s['expire']}>
                <div className={s['expire__icon']}>
                    <Progress
                        type="circle"
                        percent={percent}
                        size={50}
                        strokeWidth={10}
                        style={{
                            marginRight: 8,
                        }}
                        strokeColor={color}
                        trailColor={'#3ac27029'}
                        format={() => ''}
                    />
                </div>
                <div className={s['expire__progress']}>
                    <div className={s['expire__label']}>Срок действия</div>
                    <div ref={timer}
                        className={s['expire__time']}
                        style={{ color: color }}
                    >{timeout}</div>
                </div>
            </div>
        </div>
    )
}
