import React from 'react'
import s from './PaymentsStatus.module.scss'
import { IoAlertCircleSharp } from 'react-icons/io5'
import { useSelector } from 'react-redux'

export default function PaymentsStatus() {
    const data = useSelector((state) => state.bill)
    let color = data.status !== 'paid' ? 'danger' : 'success'
    let text = data.status !== 'paid' ? ['Срок действия счета истек', 'Пожалуйста, создайте новый счет-фактуру']
        : ['Счет оплачен', 'Спасибо за оплату, через несколько минут вы получите уведомление']

    return (
        <div className={s['status-container']}>
            <div className={s["status-hint"]} data-type={color}>
                <span className={s["status-hint__icon"]}>
                    <IoAlertCircleSharp />
                </span>
                <span className={s["status-hint__heading-text"]}>{text[0]}</span>
                <p className={s["status-hint__description"]}>{text[1]}</p>
            </div>
        </div>
    )
}
