import React from 'react';
import { BrowserRouter as Router, Routes, Route, redirect, Redirect, useLocation, Navigate, useNavigate } from "react-router-dom";
import './assets/scss/global.scss';
import MainPage from './components/MainPage/MainPage';
import { Provider } from 'react-redux';
import { store } from './store';
import MainMenu from './components/MainMenu/MainMenu';
import Page404 from './pages/Page404';
import Payments from './pages/Payments';
import BillPage from './pages/BillPage';

function App() {
  function MainBox(props) {
    return (
      <>
        {/* <Header /> */}
        {props.children}
        <MainMenu />
        {/* <Footer /> */}
      </>
    )
  }


  return (
    <>
      <Provider store={store}>
        <Router>
          <Routes>
            <Route key={'main'}
              exact={true}
              path={'/'}
              element={
                <MainBox>
                  <MainPage />
                </MainBox>
              }
            />
            <Route key={'main'}
              exact={true}
              path={'/wallet'}
              element={
                <MainBox>
                  <MainPage />
                </MainBox>
              }
            />
            <Route key={'payments'}
              exact={true}
              path={'/payments'}
              element={
                <MainBox>
                  <Payments />
                </MainBox>
              }
            />

            <Route key={'pay'}
              exact={true}
              // path pay/{id}
              path={'/pay/:uuid'}
              element={
                <BillPage />
              }
            />









              {/* Любые другие URL */}
            <Route path="*"
              element={
                <MainBox>
                  {/* <MainPage /> */}
                  <Page404 />
                </MainBox>
              } />
          </Routes>
        </Router>
      </Provider>
    </>
  );
}

export default App;
