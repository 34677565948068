import "./StarIcon.scss";
import { Suspense, useState } from "react";
import { motion, Variants, Transition } from "framer-motion";
import { StarIcon } from "./StarIcon";
import { CheckCircleOutlined , CloseCircleOutlined } from "@ant-design/icons";

// Ported from https://codepen.io/popmotion/pen/oNGxjpr?editors=1111
export default function Button({ text = 's' }) {
    const [isHover, setIsHover] = useState(false);
    const [isLiked, setIsLiked] = useState(false);

    return (
        <div className="StarIcon">
            <motion.button
                initial={false}
                animate={[isLiked ? "liked" : "unliked", isHover ? "hover" : "rest"]}
                whileTap="press"
                variants={buttonVariants}
                onHoverStart={() => setIsHover(true)}
                onHoverEnd={() => setIsHover(false)}
                onClick={() => setIsLiked(!isLiked)}
            >
                <motion.div
                    className="icon"
                    style={{
                        pointerEvents: "none"
                    }}
                    variants={{
                        liked: { opacity: 0, transition: iconFadeTransition },
                        hover: isLiked
                            ? { opacity: 0, transition: iconFadeTransition }
                            : { opacity: 1 }
                    }}
                >
                    <Suspense style={{
                        pointerEvents: "none"
                    }} fallback={null}>
                        <StarIcon isHover={isHover} isLiked={isLiked} />
                    </Suspense>
                </motion.div>
                <div className="label">
                    <motion.span variants={labelTextVariants} className="default">
                        Send
                        <motion.span variants={successTextVariants} className="success">
                            ed
                        </motion.span>
                    </motion.span>
                </div>
                <div className="number">
                    <motion.span variants={currentCountVariants} className="current">
                        <CloseCircleOutlined />
                    </motion.span>
                    <motion.span variants={newCountVariants} className="new">
                        <CheckCircleOutlined />
                    </motion.span>
                </div>
            </motion.button>
        </div>
    );
}

const iconFadeTransition = { duration: 0.2, delay: 0.3 };

const buttonVariants = {
    rest: {
        "--button-star-greyscale": '100%',
        "--button-star-contrast": "0%",
        transition: { duration: 0.2 },
        scale: 0.7,
    },
    hover: {
        "--button-star-greyscale": "0%",
        "--button-star-contrast": "100%",
        scale: 0.9,
        y: -2
    },
    press: { scale: 1 }
};

const labelTextVariants = {
    unliked: { x: 24 },
    liked: { x: -46 }
};

const successTextVariants = {
    unliked: { opacity: 0 },
    liked: { opacity: 1 }
};

const likedTransition = {
    duration: 0.25,
    delay: 0.3
};

const currentCountVariants = {
    unliked: { opacity: 1, y: 0, transition: { duration: 0.25 } },
    liked: { opacity: 0, y: -40, transition: likedTransition }
};

const newCountVariants = {
    unliked: { opacity: 0, y: 40, transition: { duration: 0.25 } },
    liked: { opacity: 1, y: 0, transition: likedTransition }
};
