import React from 'react'
import { Alert, message } from 'antd';
import './WalletPanel.scss'
import { ArrowUpOutlined, ArrowDownOutlined, RadarChartOutlined } from '@ant-design/icons';
import Withdraw from '../Withdraw/Withdraw';
import Deposit from '../Deposit/Deposit';
import CreateBill from '../CreateBill/CreateBill';


export default function WalletPanel() {
    return (
        <section className='WalletPanel'>
            <Withdraw />
            <Deposit />
            <CreateBill/>
        </section>
    )
}

