import React, { useEffect, useRef, useState } from 'react'
import Drawer from '../Drawer/Drawer'
import MenuButton from '../MenuButton/MenuButton'
import { ArrowDownOutlined, HeatMapOutlined, CopyOutlined, UploadOutlined } from '@ant-design/icons';
import { useClipboard } from 'use-clipboard-copy';
import './Deposit.scss'
import { QRCode, message } from 'antd';
import { shareOnMobile } from "react-mobile-share";


const QRCodeBlock = ({ link = 'https://ant.design/' }) => {
  const elem = useRef(null)
  const [size, setSize] = useState(280)

  // при изменении размера окна, менять размер QR-кода
  useEffect(() => {
    const handleResize = () => {
      if (elem.current === null) return
      if (elem.current.offsetWidth === size) return
      console.log('размер окна изменился');
      setSize(elem.current.offsetWidth)
    }
    // при монтировании компонента
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])


  return (
    <div className='QR'>
      <span className='desc'>Покажите QR-код</span>
      <div ref={elem} className='qr'>
        <QRCode type="canvas" size={size} value={link} />
      </div>
    </div>
  )
}

const AddressBlock = ({ address = 'EQD4XBMqr0CoiOAo8aPR9Udu6Tu1NoQz5WoXQH1arxJmUqRh' }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const clipboard = useClipboard();
  const share = () => {
    const imgBase64 = "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2...";
    // if мобильное устройство то ...
    if (window.innerWidth < 768) {
      shareOnMobile(
        {
          text: "Hey checkout our package react-mobile-share",
          url: "https://www.npmjs.com/package/react-mobile-share",
          title: "React-Mobile-Share",
          images: [imgBase64]
        },
        (message) => alert(message)
      )
    } else {
      // else десктоп
      try {
        navigator.share({
          title: 'Поделиться',
          text: 'Поделиться адресом',
          url: address,
        })
      } catch {
        console.log('Не удалось поделиться');
      }
    }
  }
  const success = () => {
    messageApi.open({
      type: 'success',
      key: 'copped',
      content: 'Скопировано',
    });
  };
  let addr = [address.slice(0, 4), address.slice(-4)]
  const copyAddress = () => {
    try {
      clipboard.copy(address);
    } catch {
      console.log('Не удалось скопировать адрес');
    }
    success()
  }
  return (
    <>
      {contextHolder}
      <div className='Address'>
        <span className='desc'>Или используйте адрес</span>
        <span onClick={() => copyAddress()} className='address'>{address}</span>
        <div className='panel'>
          <div onClick={() => copyAddress()}><CopyOutlined />Копировать</div>
          <div onClick={() => share()}><UploadOutlined />Поделиться</div>
        </div>

      </div>
    </>
  )
}

function DepositPanel({ active, setActive }) {
  return (
    <Drawer active={active} setActive={setActive}>
      <div className='Deposit'>
        <div className='header'>
          <span className='img'>
            <HeatMapOutlined style={{ fontSize: '5rem', color: "#45aef5" }} />
          </span>
          <h1>Получить TON<br />и другие токены</h1>
        </div>
        <div className='body'>
          <QRCodeBlock />
          <AddressBlock />
        </div>
      </div>
    </Drawer>
  )
}

export default function Deposit(props) {
  const [active, setActive] = useState(false)
  useEffect(() => {
    if (active) {
      document.getElementById('root').classList.add('small')
    } else {
      document.getElementById('root').classList.remove('small')
    }
  }, [active])
  return (
    <div>
      <DepositPanel active={active} setActive={setActive} />
      <MenuButton text='Получить' icon={<ArrowDownOutlined />} onClick={() => setActive(true)} />
    </div>

  )
}

