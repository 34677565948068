import React from 'react'
import s from './Help.module.scss'
import { BsTelegram } from 'react-icons/bs'

export default function Help() {
  return (
      <div className={s['help__content']}>
          <div className={s['help__description']}>Пожалуйста, свяжитесь с нами, если у вас возникнут какие-либо проблемы</div>
          <div className={s['help__icon-wrapper']}>
              <a href="https://t.me/amal_agishev" class={s["help__icon"]} target="_blank" rel="noreferrer"><BsTelegram/></a>
              <a href="https://t.me/amal_agishev" class={s["help__icon"]} target="_blank" rel="noreferrer"><BsTelegram /></a>
          </div>
      </div>
  )
}
