import React, { useState } from 'react';
import './MainPage.scss';

import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Link } from 'react-router-dom';
import QRScanner from '../QRScanner/QRScanner';
import AddressData from '../AddressData/AddressData';
import WalletPanel from '../WalletPanel/WalletPanel';
import InfoBlock from '../InfoBlock/InfoBlock';
import TonC from '../TonC/TonLoader';
import TonLoader from '../TonC/TonLoader';
import HeaderPanel from '../HeaderPanel/HeaderPanel';
import CopyPanel from '../CopyPanel/CopyPanel';
import { slice } from '../../utils/utils';


function BalancePanel() {
    let address = 'EQD4XBMqr0CoiOAo8aPR9Udu6Tu1NoQz5WoXQH1arxJmUqRh'
    return (
        <section className='MainPage-balance'>
            <h1>101.123123 TON</h1>
            <CopyPanel toCopy={address}>
                <div className='AddressData'>{slice(address)}</div>
            </CopyPanel>
        </section>
    )
}
function MainPage({ setModalActive }) {
    return (
        <>
            <HeaderPanel text='Кошелек'><QRScanner /></HeaderPanel>
            <BalancePanel />
            <WalletPanel />
            <InfoBlock />
            {/* <TonLoader /> */}
        </>
    );
}

export default MainPage;