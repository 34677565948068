import axios from 'axios'
import { APIEndpoint } from '../../config'
// класс API для отправки запросов на сервер

// сделать из словаря строку формата ключ=значение
const makeQueryString = (params) => {
    return Object.keys(params).map(key => `${key}=${params[key]}`).join('&')
}

// if UUID
export const isUUID = (uuid) => {
    return !uuid.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/)
}

export default class Api {
    // конструктор класса
    constructor({ baseUrl = APIEndpoint, headers = {} }) {
        this.baseUrl = baseUrl
        this.headers = headers
    }


    /**
     * метод для отправки запроса на сервер принимает путь и параметры запроса
     * @param {String} path URL запроса
     * @param {String} type тип запроса (по умолчанию GET) 
     * @param {JSON} data тело запроса
     * @param {Boolean} auth флаг авторизации (по умолчанию false)
     * @returns Response ответ сервера
     */
    async _fetch(path, type = "GET", data = {}, auth = false) {
        // возвращает результат запроса
        let url = `${this.baseUrl}${path}`
        if (type === 'GET') url += `?${makeQueryString(data)}`
        //создать Promise через axios отправляем запрос на сервер
        return new Promise((resolve, reject) => {
            axios({
                method: type,
                url: url,
                headers: this.headers,
                data: data
            })
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    reject(error)
                })
        })
    }
}
