import React from 'react'
import s from './PaymentsActions.module.scss'
import { AiOutlineQrcode } from 'react-icons/ai'
import CopyPanel from '../../../CopyPanel/CopyPanel'
import { BiCopy } from 'react-icons/bi'
import Expire from '../Expire/Expire'
import StatusConfirm from '../StatusConfirm/StatusConfirm'
import { QRCode } from 'antd'
import { useSelector } from 'react-redux'

const toWai = (amount) => {
    return amount * 1000000000
}
const makeUrl = (address = '', amount = 1, comment) => {
    return `https://app.tonkeeper.com/transfer/${address}?amount=${toWai(amount)}&text=${comment}`
    // return `ton://transfer/${address}?amount=${amount}&text=${message}`
}


const PayWithTonkeeper = ({ data: { address, total, left_paid, comment }, color = "#45aef5c7", link }) => {
    const totalToPay = left_paid > 0 ? left_paid : total
    let style = {
        "background": color
    }
    let url = `https://app.tonkeeper.com/transfer/${address}?amount=${toWai(totalToPay)}&text=${comment}`
    const openLink = () => {
        window.open(url, '_blank')
    }
    return (
        <div onClick={openLink} style={style} className={s['checkout__pay__btn']}>
            <span>Оплата через Tonkeeper</span>
            <img src='https://tonkeeper.com/assets/logo.svg'></img>
        </div>
    )

}


export default function PaymentsActions() {
    const data = useSelector((state) => state.bill)
    let width = window.innerWidth
    let url = makeUrl(data.address, data.left_paid, data.comment)
    return (
        <div className={s['paymentsActions']}>
            <div className={s['checkout']}>
                {width < 768 &&
                    <div className={s['checkout__pay']}>
                        <PayWithTonkeeper data={data} />
                    </div>
                }
                <div className={s['checkout__info']}>
                    {width > 768 && <div className={s['info__qr-wrapper']}><QRCode size={128} value={url || '-'} /></div>}
                    <div className={s['info__recipient']}>
                        <div>
                            <p className={s['recipient__title']}>Адрес кошелька получателя</p>
                            <div className={s['recipient__address']}>
                                <span className={s['address__text']}>{data.address}</span>
                                <div className={s['address__buttons']}>
                                    {width < 768 && <AiOutlineQrcode />}
                                    <CopyPanel toCopy={data.address} alert='Адрес скопирован' >
                                        <BiCopy />
                                    </CopyPanel>
                                </div>
                            </div>
                        </div>
                        <p className={s["info__notification-alarm"]}>
                            Когда статус вашего платежа изменится, мы отправим вам уведомление
                            <button className={s["notification-alarm__email"]}>Оставьте свой адрес электронной почты</button>
                        </p>
                    </div>
                </div>
                <div className={s['checkout__info']}>
                    <div className={s['info__recipient']}>
                        <div>
                            <p className={s['recipient__title']}>Комментарий к транзакции</p>
                            <div className={s['recipient__address']}>
                                <span className={s['address__text']}>{data.comment}</span>
                                <div className={s['address__buttons']}>
                                    <CopyPanel toCopy={data.comment} alert='Комментарий скопирован' >
                                        <BiCopy />
                                    </CopyPanel>
                                </div>
                            </div>
                        </div>
                        <p className={s["info__notification-alarm"]}>
                            Комментарий необходим для идентификации платежа.<br /><span className={s['info__notification-alarm-alert']}>обязательно укажите комментарий.</span>
                        </p>
                    </div>
                </div>

                <div className={s['checkout__status']}>
                    <Expire />
                    <StatusConfirm />
                </div>
            </div>
        </div>
    )
}
