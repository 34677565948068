import { APIEndpoint } from '../../config/index.js'
import Api, { isUUID } from './index.js'
// класс с методами для работы с API передать в конструктор класса Api параметры
// baseUrl и headers
class PaylinkApi extends Api {
    constructor() {
        super({
            baseUrl: APIEndpoint,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
    }
    // метод для получения платежа по id
    async getPayment(id) {
        // если ID не передан, то возвращаем ошибку
        if (!id) return new Error('Не передан ID платежа')
        // если id не UUID, то возвращаем ошибку
        if (isUUID(id)) return new Error('Неверный формат ID платежа')


        return await this._fetch(`/payment/info`, 'POST',
            {
                "serch": "",
                "uuid": id
            })
    }

}

let payLinkAPI = new PaylinkApi()
export default payLinkAPI;