import React, { useEffect, useState } from 'react'
import Drawer from '../Drawer/Drawer'
import MenuButton from '../MenuButton/MenuButton'
import { EditOutlined } from '@ant-design/icons';
import { CreateBillPanel } from '../CreateBillPanel/CreateBillPanel';


export default function CreateBill(props) {
  const [active, setActive] = useState(false)
  useEffect(() => {
    if (active) {
      document.getElementById('root').classList.add('small')
    } else {
      document.getElementById('root').classList.remove('small')
    }
  }, [active])
  return (
    <div>
      <CreateBillPanel active={active} setActive={setActive} />
      <MenuButton text='Выставить счет' icon={<EditOutlined />} onClick={() => setActive(true)} />
    </div>

  )
}

