import React, { useEffect } from 'react'
import s from './PaymentsDetails.module.scss'
import { BiCopy } from 'react-icons/bi';
import CopyPanel from '../../../CopyPanel/CopyPanel';
import { AiOutlineInfoCircle, AiFillCheckCircle } from 'react-icons/ai';
import { useSelector } from 'react-redux';


export default function PaymentsDetails({ active }) {
    const data = useSelector((state) => state.bill)
    const redirect = () => {
        console.log(data)
        let url = data?.clientData?.to || null
        if (!url) return;
        window.location.href = url;
    }
    return (
        <>
            <div className={s['payment-details']}>
                <div className={s['payment-details__wrapper']} style={data.status === 'paid' ? { "justifyContent": "center" } : null}>
                    <div className={s['payment-details__amount_wrapper']}>
                        {data.left_paid !== data.total && data.left_paid > 0 ?
                            <div className={s['payment-details__total']}>
                                <div className={s['payment-details__amount']}>{data.total.toFixed(4)} {data.payCurrency}</div>
                            </div>
                            : null}
                        <div className={s['payment-details__left']}>
                            <div className={s['payment-details__amount']}>{data.left_paid > 0 ? data.left_paid.toFixed(4) : data.total.toFixed(4)}</div>
                            <div className={s['payment-details__amount']}>{data.payCurrency}</div>
                        </div>
                    </div>
                    <CopyPanel toCopy={data.left_paid > 0 ? data.left_paid : data.total} alert='Сумма успешно скопирована'>
                        <div className={s['copy-icon']}>
                            <BiCopy />
                        </div>
                    </CopyPanel>
                </div>
                {data.active ?
                    <>
                        <div className={s['payment-details__subheading']}>
                            <span>Сеть · {data.chain}</span>
                        </div>
                        <div className={s['payment-details__fee']}>
                            <AiOutlineInfoCircle />
                            <span>
                                {data.taxFee === 'client' ? 'Вы платите комиссию сети ' : 'Получатель платит комиссию сети'}

                            </span>
                        </div>
                    </> :
                    <>
                        {data?.clientData?.to &&
                            <div className={s['status-redirect']}>
                                <div onClick={redirect} className={s['status-redirect__link']}>
                                    Вернуться в сервис
                                    <AiFillCheckCircle />
                                </div>
                            </div>
                        }
                    </>
                }

            </div>
        </>

    )
}
