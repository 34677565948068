import React from 'react'
import { Header } from './components'

export default function Bill() {
    return (
        <div className='pay'>
            <div className='pay__content'>
                <Header />
            </div>
        </div>
    )
}
