import { InputNumber, Slider } from "antd";
import Drawer from "../Drawer/Drawer";
import './CreateBillPanel.scss';
import { useState } from "react";
import Button from "../StarIcon";

// установить длительность set hour
const SetHour = ({ setHourTimer }) => {
    const formatter = (value) => `${value}H`;
    const makeMark = (color, value) => {
        return {
            style: {
                color: color,
            },
            label: <strong>{value}</strong>,
        }
    }
    const marks = {
        12: makeMark('ffffff73', '12h'),
        24: makeMark('ffffff73', '1D'),
        96: makeMark('ffffff73', '4D'),
    };
    return (

        <div className="Slider">
            <span>Время жизни счета:</span>
            <Slider
                tooltip={{
                    formatter,
                }}
                min={3}
                max={96}
                marks={marks}
                step={3}

                style={{
                    width: '100%',
                    color: "white"
                }}
                trackStyle={{
                    backgroundColor: '#7676ff',
                    color: "white"
                }}
                railStyle={{
                    backgroundColor: "#d5d5d5",
                    color: "white"
                }}
                handleStyle={{
                    backgroundColor: '#7676ff',
                    color: "white"
                }}
            />
        </div>
    )
}

export function CreateBillPanel({ active, setActive }) {
    const [totalTon, setTotalTon] = useState(1000)
    return (
        <Drawer active={active} setActive={setActive}>
            <div className="CreateBillPanel">
                <div>CreateBill</div>
                <div className="Forms">
                    <InputNumber
                        style={{ width: '100%' }}
                        prefix="TON"
                        defaultValue={totalTon}
                        parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                        onChange={(value) => setTotalTon(value)}
                    />
                    <SetHour />
                    <Button/>
                </div>
            </div>
        </Drawer>
    )
}