import React, { useEffect } from 'react'
// импортировать Header.module.scss
// import { GiSwanBreeze } from "react-icons/gi";
import { SiFoodpanda } from "react-icons/si";
import s from './Header.module.scss'
import CashBack from '../CashBack/CashBack';
import PaymentsDetails from '../PaymentsDetails/PaymentsDetails';
import PaymentsActions from '../PaymentsActions/PaymentsActions';
import PaymentsStatus from '../PaymentsStatus/PaymentsStatus';


import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './store/store';
import { setBillActive, setBillData } from './store/billSlice';
import Loader from '../../../Loader/Loader';
import Help from '../Help/Help';
import payLinkAPI from '../../../../utils/API/paylink';
import { useParams } from 'react-router-dom';

export default function Header(props) {
  return (

    <Provider store={store} >
      <Bill />
    </Provider>
  )
}


function Bill(props) {
  // получить параметр из URL :id
  const { uuid } = useParams()
  const data = {
    total: '0.2',
    currency: 'TON',
    network: 'TON',
    address: 'EQD4XBMqr0CoiOAo8aPR9Udu6Tu1NoQz5WoXQH1arxJmUqRh',
    comment: 'B1S3aqE',
    dataEnd: 1688725440694,
    orderExp: 180, // 3h,
  }
  const [loading, setLoading] = React.useState(true)
  const active = useSelector((state) => state.bill.active)
  const dispatch = useDispatch()

  const updateDate = (timerId = null) => {
    payLinkAPI.getPayment(uuid).then((data) => {
      console.log('uuid', uuid);
      dispatch(setBillActive(data.state === 0))
      dispatch(setBillData(data.result))
      setLoading(false)
      // отключить таймер
      if (data.state === 1 && timerId !== null) { clearInterval(timerId) }
    }).catch((err) => {
      console.log(err);
    })
  }

  useEffect(() => {
    updateDate()
    // запустить таймер с циклом обновления данных
    const timerId = setInterval(() => updateDate(timerId), 30000)
  }, [])


  return (
    <>
      {loading ? <Loader /> :
        <>
          <div className={s.content__info}>
            <div className={s.Header}>
              <div className={s.Header_logo}>
                <SiFoodpanda />
                
                <span>DOM Payments</span>
              </div>
              <div className={s.Header_badges}>
                {/* <CashBack /> */}
              </div>
            </div>
            {!active && <PaymentsStatus />}
            {<PaymentsDetails />}

            {active && <PaymentsActions />}
          </div>

        </>
      }
      <Help />
    </>
  )

}