import React, { useEffect, useState } from 'react'
import { DownOutlined } from '@ant-design/icons';
import './Drawer.scss'
export default function Drawer({ active, setActive, children }) {
    const [classList, setClassList] = useState('')
    useEffect(() => {
        // пауза в 1 секунду
        setTimeout(() => {
            if (active) {
                setClassList(' active')
            } else {
                setClassList('')
            }
        }, 100);
    }, [active])
    if (active) {
        return (
            <section style={active ? {} : { display: 'none' }} className={`DrawerPanel${classList}`} >
                <div className='context'>
                    <div className='header'>
                        <div onClick={() => setActive(false)} className='exitBtn'><DownOutlined /></div>
                    </div>
                    <div>{children}</div>
                </div>
            </section>
        )
    } else {
        // подождать 1 секунду, чтобы не было видно анимации
        return null
        }
}
