import './MenuButton.scss'
const MenuButton = ({ text, icon, onClick }) => {
    text = text || 'Кнопка'
    icon = icon || ''
    onClick = onClick || (() => { })
    return (
        <div className='MenuButton' onClick={onClick}>
            <div className='circle'>{icon}</div>
            <span className='text'>{text}</span>
        </div>
    )
}
export default MenuButton