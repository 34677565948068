import { createSlice } from '@reduxjs/toolkit'

export const billSlice = createSlice({
    name: 'counter',
    initialState: {
        // "id": "35634b3e-0230-465c-a364-c6779eb3d68e",
        // "code": "CodeTest",
        // "address": "addr-asdasdasdads-Test",
        // "comment": "CommentTest",
        // "type": "facility",
        // "amount": 55,
        // "currency": "TON",
        // "payCurrency": "TON",
        // "chain": "TON",
        // "taxFee": "client",
        // "serviceFee": 0.25,
        // "total": 15.25,
        // "orderID": null,
        // "lifeTime": 120,
        // "expiresAt": "2023-07-13T10:10:10.0Z",
        // "status": "active",
        // "transactionHash": null,
        // "merchant": "8ef09cc0-e9b8-48ad-b7ed-5f8f110a8c3e"
    },
    reducers: {
        setBillData: (state, action) => {
            // ключи словаря action.payload
            Object.keys(action.payload).forEach(element => {
                state[element] = action.payload[element]
            });
        },
        setBillActive: (state, action) => {
            state.active = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { setBillData, setBillActive } = billSlice.actions

export default billSlice.reducer