import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    theme: 'light',
    themeType: ['dark', 'light', 'lightNews'],
};

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setTheme: (state, action) => {
            if (state.themeType.includes(action.payload)) {
                state.theme = action.payload
            } else {
                state.theme = 'light'
            }
        },
    },
})

export const {
    setTheme
} = themeSlice.actions

export default themeSlice.reducer