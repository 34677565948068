import React from 'react'
import Err404 from '../components/Err404/Err404'



export default function Page404() {
    return (
        <Err404 />
    )
}
