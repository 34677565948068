import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { QrcodeOutlined } from '@ant-design/icons';
import './QRScanner.scss';
import Drawer from '../Drawer/Drawer';
import QRScan from '../QRScan/QRScan';

function QRScannerPanel({ active, setActive }) {
    return (
        <Drawer active={active} setActive={setActive}>
            <div>Content</div>
            <QRScan/>
        </Drawer>
    )
}


export default function QRScanner(props) {
    const [active, setActive] = useState(false)
    useEffect(() => {
        if (active) {
            document.getElementById('root').classList.add('small')
        } else {
            document.getElementById('root').classList.remove('small')
        }
    }, [active])
    return (
        <div className='QRScanner'>
            <QRScannerPanel active={active} setActive={setActive} />
            <button className='QRScanner-btn' onClick={() => setActive(true)}><QrcodeOutlined className='c__primary' /></button>
        </div>

    )
}