import React, { useEffect, useState } from 'react'
import HeaderPanel from '../HeaderPanel/HeaderPanel'
import './PaymentsPanel.scss'
import CopyPanel from '../CopyPanel/CopyPanel'
import {
  CopyOutlined, HolderOutlined, SafetyOutlined, WarningOutlined,
  ClockCircleOutlined, CheckCircleOutlined, CloseCircleOutlined,
  QrcodeOutlined, PlusOutlined
} from '@ant-design/icons'
import { Tooltip } from 'antd'
import { CreateBillPanel } from '../CreateBillPanel/CreateBillPanel'

const Filters = () => { }

function CreateBill(props) {
  const [active, setActive] = useState(false)
  useEffect(() => {
    if (active) {
      document.getElementById('root').classList.add('small')
    } else {
      document.getElementById('root').classList.remove('small')
    }
  }, [active])
  return (
    <div className='QRScanner'>
      <CreateBillPanel active={active} setActive={setActive} />
      <button className='QRScanner-btn' onClick={() => setActive(true)}><PlusOutlined className='c__primary' /></button>
    </div>

  )
}

const Transactions = () => {
  const Transaction = ({ status = 'active', amount = '10', time = '12:00', url = 'https://google.com', date = '12.12.2020' }) => {
    const Status = ({ status = 'active' }) => {
      const variants = {
        active: {
          icon: <ClockCircleOutlined style={{ color: '#7676ff' }} />,
          text: 'В обработке'
        },
        success: {
          icon: <CheckCircleOutlined style={{ color: '#42ff42' }} />,
          text: 'Успешно'
        },
        error: {
          icon: <WarningOutlined style={{ color: 'yellow' }} />,
          text: 'Ошибка'
        },
        // не отплатил
        notPaid: {
          icon: <CloseCircleOutlined style={{ color: 'red' }} />,
          text: 'Не оплачено'
        }
      }
      if (!variants[status]) return '-'


      return (
        <div className='status'>
          <Tooltip title={variants[status].text} color={'blue'} key={'blue'}>
            <span className='status__icon'>{variants[status].icon}</span>
          </Tooltip>
        </div>
      )
    }

    return (
      <div className='transaction'>
        <Status status={status} />
        <div className='amount'>{amount} TON</div>
        <div className='time'>{time}</div>
        <div className='url'><UrlLink url={url} /></div>
        <div className='date'>{date}</div>
        <div className='action'><HolderOutlined /></div>
      </div>
    )
  }

  const UrlLink = ({ url }) => {
    return (
      <CopyPanel toCopy={url}>
        <div className=''>{<CopyOutlined />}</div>
      </CopyPanel>
    )
  }

  // у нее статус, сумма, времени до завершения, url, дата выстравления
  return (
    <section className='PaymentsPanel-transactions'>
      <h1>Транзакции</h1>
      <Transaction
        status='notPaid'
        amount='10'
        time='12:00'
        url='https://google.com'
        date='12.12.2020'
      />
      <Transaction
        status='error'
        amount='10'
        time='12:00'
        url='https://google.com'
        date='12.12.2020'
      />
      <Transaction
        status='active'
        amount='10'
        time='12:00'
        url='https://google.com'
        date='12.12.2020'
      />
      <Transaction
        status='success'
        amount='10'
        time='12:00'
        url='https://google.com'
        date='12.12.2020'
      />

    </section>
  )
}


export default function PaymentsPanel() {
  return (
    <section className='PaymentsPanel'>
      <HeaderPanel text='Платежи'><CreateBill/></HeaderPanel>
      <Transactions />
    </section>
  )
}
